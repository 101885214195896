@import '../../assets/styles/variables.module.scss';
@import '../../assets/styles/mixins.module.scss';


.full_screen_center_center{
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;

    @include flex-column-center-center;
    background-color: $violet-primary;

    overflow: hidden;
}

.full_screen_start_center{
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;

    @include flex-column-start-center;
    background-color: $violet-primary;

    overflow: scroll;
}
















// only mobile css section
@media only screen and (max-width: 991px){
    .full_screen_center_center{
        width: 100%;
        height: 100dvh;

        @include flex-column-center-center;
        background-color: $violet-primary;
        // background: linear-gradient(violet 0% 90%, aqua 90% 100%,);

        overflow-x: hidden;
    }


    .full_screen_start_center{
        width: 100%;
        height: 100vh;

        background-color: $violet-primary;
        overflow-y: hidden;

        position: fixed;
    }
}